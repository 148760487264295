.easy-checkbox[data-v-e0a0b7f0] {
	position: relative;
	width: 1.3em;
	height: 1.3em;
	font-size: var(--easy-table-body-row-font-size);
	margin: 0 auto
}

.easy-checkbox label[data-v-e0a0b7f0] {
	cursor: pointer;
	display: inline;
	line-height: 1.3em;
	vertical-align: top;
	clear: both
}

.easy-checkbox label[data-v-e0a0b7f0]:before, .easy-checkbox label[data-v-e0a0b7f0]:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0
}

.easy-checkbox label[data-v-e0a0b7f0]:before {
	width: 1.3em;
	height: 1.3em;
	background: #fff;
	border: 1px solid rgba(0, 0, 0, .54);
	border-radius: .125em;
	cursor: pointer;
	transition: background .3s
}

.easy-checkbox input[type=checkbox][data-v-e0a0b7f0] {
	outline: 0;
	visibility: hidden;
	width: 1.3em;
	margin: 0;
	display: block;
	float: left;
	font-size: inherit
}

.easy-checkbox input[type=checkbox]:checked + label[data-v-e0a0b7f0]:before {
	border: none
}

.easy-checkbox input[type=checkbox]:checked + label[data-v-e0a0b7f0]:after {
	transform: translate(.2em, .3038461538em) rotate(-45deg);
	width: .9em;
	height: .45em;
	border: .13em solid #fff;
	border-top-style: none;
	border-right-style: none
}

.easy-checkbox input[type=checkbox].allSelected + label[data-v-e0a0b7f0]:before {
	border: none
}

.easy-checkbox input[type=checkbox].allSelected + label[data-v-e0a0b7f0]:after {
	transform: translate(.2em, .3038461538em) rotate(-45deg);
	width: .9em;
	height: .45em;
	border: .13em solid #fff;
	border-top-style: none;
	border-right-style: none
}

.easy-checkbox input[type=checkbox].partSelected + label[data-v-e0a0b7f0]:before {
	border: none
}

.easy-checkbox input[type=checkbox].partSelected + label[data-v-e0a0b7f0]:after {
	transform: translate(.2em, .5875em);
	width: .9em;
	height: .45em;
	border: .125em solid #fff;
	border-bottom-style: none;
	border-right-style: none;
	border-left-style: none
}

*[data-v-e0a0b7f0], *[data-v-e0a0b7f0]:before, *[data-v-e0a0b7f0]:after {
	box-sizing: border-box
}

.easy-checkbox input[type=checkbox].allSelected + label[data-v-e0a0b7f0]:before, .easy-checkbox input[type=checkbox].partSelected + label[data-v-e0a0b7f0]:before {
	background: var(--51ab8a49)
}

.easy-checkbox[data-v-7e69a276] {
	position: relative;
	width: 1.3em;
	height: 1.3em;
	font-size: var(--easy-table-body-row-font-size);
	margin: 0 auto
}

.easy-checkbox label[data-v-7e69a276] {
	cursor: pointer;
	display: inline;
	line-height: 1.3em;
	vertical-align: top;
	clear: both
}

.easy-checkbox label[data-v-7e69a276]:before, .easy-checkbox label[data-v-7e69a276]:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0
}

.easy-checkbox label[data-v-7e69a276]:before {
	width: 1.3em;
	height: 1.3em;
	background: #fff;
	border: 1px solid rgba(0, 0, 0, .54);
	border-radius: .125em;
	cursor: pointer;
	transition: background .3s
}

.easy-checkbox input[type=checkbox][data-v-7e69a276] {
	outline: 0;
	visibility: hidden;
	width: 1.3em;
	margin: 0;
	display: block;
	float: left;
	font-size: inherit
}

.easy-checkbox input[type=checkbox]:checked + label[data-v-7e69a276]:before {
	border: none
}

.easy-checkbox input[type=checkbox]:checked + label[data-v-7e69a276]:after {
	transform: translate(.2em, .3038461538em) rotate(-45deg);
	width: .9em;
	height: .45em;
	border: .13em solid #fff;
	border-top-style: none;
	border-right-style: none
}

.easy-checkbox input[type=checkbox].allSelected + label[data-v-7e69a276]:before {
	border: none
}

.easy-checkbox input[type=checkbox].allSelected + label[data-v-7e69a276]:after {
	transform: translate(.2em, .3038461538em) rotate(-45deg);
	width: .9em;
	height: .45em;
	border: .13em solid #fff;
	border-top-style: none;
	border-right-style: none
}

.easy-checkbox input[type=checkbox].partSelected + label[data-v-7e69a276]:before {
	border: none
}

.easy-checkbox input[type=checkbox].partSelected + label[data-v-7e69a276]:after {
	transform: translate(.2em, .5875em);
	width: .9em;
	height: .45em;
	border: .125em solid #fff;
	border-bottom-style: none;
	border-right-style: none;
	border-left-style: none
}

*[data-v-7e69a276], *[data-v-7e69a276]:before, *[data-v-7e69a276]:after {
	box-sizing: border-box
}

.easy-checkbox input[type=checkbox]:checked + label[data-v-7e69a276]:before {
	background: var(--fdaf7e9e)
}

.easy-data-table__rows-selector[data-v-4ca5de3a] {
	display: inline-block;
	min-width: 45px;
	position: relative;
	margin: 0 10px;
	width: var(--easy-table-rows-per-page-selector-width)
}

.easy-data-table__rows-selector .rows-input__wrapper[data-v-4ca5de3a] {
	height: 20px;
	border-bottom: 1px solid var(--easy-table-footer-font-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px;
	cursor: pointer
}

.easy-data-table__rows-selector .rows-input__wrapper .triangle[data-v-4ca5de3a] {
	display: inline-block;
	vertical-align: middle;
	width: 0px;
	height: 0px;
	border-top: solid 6px var(--easy-table-footer-font-color);
	border-left: solid 6px transparent;
	border-right: solid 6px transparent
}

.easy-data-table__rows-selector ul.select-items[data-v-4ca5de3a] {
	position: absolute;
	top: 20px;
	left: 0px;
	width: 100%;
	display: none;
	margin: 0;
	padding: 0;
	text-align: left;
	list-style-type: none;
	box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
	z-index: var(--easy-table-rows-per-page-selector-z-index)
}

.easy-data-table__rows-selector ul.select-items.show[data-v-4ca5de3a] {
	display: block
}

.easy-data-table__rows-selector ul.select-items.inside[data-v-4ca5de3a] {
	bottom: 0px;
	top: auto
}

.easy-data-table__rows-selector ul.select-items li[data-v-4ca5de3a] {
	cursor: pointer;
	padding: var(--easy-table-rows-per-page-selector-option-padding);
	background-color: var(--easy-table-footer-background-color)
}

.easy-data-table__rows-selector ul.select-items li.selected[data-v-4ca5de3a] {
	color: #fff;
	background-color: var(--1b889342)
}

.lds-ring[data-v-1fa3a520] {
	display: inline-block;
	position: relative;
	width: 60px;
	height: 60px
}

.lds-ring div[data-v-1fa3a520] {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 80%;
	height: 80%;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring-1fa3a520 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
	border-color: var(--26774109) transparent transparent transparent
}

.lds-ring div[data-v-1fa3a520]:nth-child(1) {
	animation-delay: -.45s
}

.lds-ring div[data-v-1fa3a520]:nth-child(2) {
	animation-delay: -.3s
}

.lds-ring div[data-v-1fa3a520]:nth-child(3) {
	animation-delay: -.15s
}

@keyframes lds-ring-1fa3a520 {
	0% {
		transform: rotate(0)
	}
	to {
		transform: rotate(360deg)
	}
}

.loader-line[data-v-7d281cac] {
	width: 100%;
	height: 3px;
	position: relative;
	overflow: hidden;
	background-color: #ddd;
	margin: 0x auto
}

.loader-line[data-v-7d281cac]:before {
	content: "";
	position: absolute;
	left: -50%;
	height: 3px;
	width: 40%;
	background-color: var(--0d327f57);
	-webkit-animation: lineAnim-7d281cac 1s linear infinite;
	-moz-animation: lineAnim-7d281cac 1s linear infinite;
	animation: lineAnim-7d281cac 1s linear infinite
}

@keyframes lineAnim-7d281cac {
	0% {
		left: -40%
	}
	50% {
		left: 20%;
		width: 80%
	}
	to {
		left: 100%;
		width: 100%
	}
}

.buttons-pagination[data-v-4c681fa2] {
	box-sizing: border-box;
	display: flex;
	padding: 0;
	border-radius: 4px
}

.buttons-pagination .item[data-v-4c681fa2] {
	box-sizing: border-box;
	cursor: pointer;
	min-width: calc(var(--easy-table-footer-font-size) * 1.8);
	line-height: calc(var(--easy-table-footer-font-size) * 1.8);
	border-top: var(--easy-table-buttons-pagination-border);
	border-bottom: var(--easy-table-buttons-pagination-border);
	border-right: var(--easy-table-buttons-pagination-border);
	text-align: center
}

.buttons-pagination .item[data-v-4c681fa2]:first-of-type {
	border-left: var(--easy-table-buttons-pagination-border);
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px
}

.buttons-pagination .item[data-v-4c681fa2]:last-of-type {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px
}

.buttons-pagination .item.button.active[data-v-4c681fa2] {
	background-color: var(--40dd4f07);
	border-left: var(--easy-table-buttons-pagination-border);
	border-color: var(--40dd4f07) !important;
	color: #fff
}

.buttons-pagination .item.button.active-prev[data-v-4c681fa2] {
	border-right: none
}

.previous-page__click-button[data-v-c9da5286], .next-page__click-button[data-v-c9da5286] {
	margin: 0 5px;
	cursor: pointer
}

.previous-page__click-button .arrow[data-v-c9da5286], .next-page__click-button .arrow[data-v-c9da5286] {
	display: inline-block;
	width: 8px;
	height: 8px;
	border-top: 2px solid #000;
	border-left: 2px solid #000
}

.previous-page__click-button .arrow.arrow-left[data-v-c9da5286], .next-page__click-button .arrow.arrow-left[data-v-c9da5286] {
	transform: rotate(135deg)
}

.previous-page__click-button .arrow.arrow-right[data-v-c9da5286], .next-page__click-button .arrow.arrow-right[data-v-c9da5286] {
	transform: rotate(-45deg)
}

.previous-page__click-button.first-page[data-v-c9da5286], .next-page__click-button.last-page[data-v-c9da5286] {
	cursor: not-allowed
}

.previous-page__click-button.first-page .arrow[data-v-c9da5286], .next-page__click-button.last-page .arrow[data-v-c9da5286] {
	border-color: #e0e0e0
}

:root {
	--easy-table-border: 1px solid #e0e0e0;
	--easy-table-row-border: 1px solid #e0e0e0;
	--easy-table-header-font-size: 12px;
	--easy-table-header-height: 36px;
	--easy-table-header-font-color: #373737;
	--easy-table-header-background-color: #fff;
	--easy-table-header-item-padding: 0px 10px;
	--easy-table-body-row-height: 36px;
	--easy-table-body-row-font-size: 12px;
	--easy-table-body-row-font-color: #212121;
	--easy-table-body-row-background-color: #fff;
	--easy-table-body-row-hover-font-color: #212121;
	--easy-table-body-row-hover-background-color: #eee;
	--easy-table-body-even-row-font-color: #212121;
	--easy-table-body-even-row-background-color: #fafafa;
	--easy-table-body-item-padding: 0px 10px;
	--easy-table-footer-background-color: #fff;
	--easy-table-footer-font-color: #212121;
	--easy-table-footer-font-size: 12px;
	--easy-table-footer-padding: 0px 5px;
	--easy-table-footer-height: 36px;
	--easy-table-rows-per-page-selector-width: auto;
	--easy-table-rows-per-page-selector-option-padding: 5px;
	--easy-table-rows-per-page-selector-z-index: auto;
	--easy-table-message-font-color: #212121;
	--easy-table-message-font-size: 12px;
	--easy-table-message-padding: 20px;
	--easy-table-loading-mask-background-color: #fff;
	--easy-table-loading-mask-opacity: .5;
	--easy-table-scrollbar-track-color: #fff;
	--easy-table-scrollbar-color: #fff;
	--easy-table-scrollbar-thumb-color: #c1c1c1;
	--easy-table-scrollbar-corner-color: #fff;
	--easy-table-buttons-pagination-border: 1px solid #e0e0e0
}

.vue3-easy-data-table {
	border: var(--easy-table-border);
	position: relative;
	box-sizing: border-box
}

.vue3-easy-data-table__main {
	border: none;
	width: 100%;
	overflow: auto;
	background-color: var(--easy-table-body-row-background-color)
}

.vue3-easy-data-table__main::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: var(--easy-table-scrollbar-track-color)
}

.vue3-easy-data-table__main::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	background-color: var(--easy-table-scrollbar-color)
}

.vue3-easy-data-table__main::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: var(--easy-table-scrollbar-thumb-color)
}

.vue3-easy-data-table__main::-webkit-scrollbar-corner {
	background-color: var(--easy-table-scrollbar-corner-color)
}

.vue3-easy-data-table__main.table-fixed table {
	table-layout: fixed
}

.vue3-easy-data-table__main.show-shadow th.shadow:after, .vue3-easy-data-table__main.show-shadow td.shadow:after {
	box-shadow: inset 6px 0 5px -3px #0003
}

.vue3-easy-data-table__main.fixed-header th {
	position: sticky;
	top: 0;
	z-index: 2
}

.vue3-easy-data-table__main.hoverable tr:hover td {
	background-color: var(--easy-table-body-row-hover-background-color);
	color: var(--easy-table-body-row-hover-font-color)
}

.vue3-easy-data-table__main.hoverable .vue3-easy-data-table__body.row-alternation .even-row:hover td {
	color: var(--easy-table-body-row-hover-font-color);
	background-color: var(--easy-table-body-row-hover-background-color)
}

.vue3-easy-data-table__main.border-cell .vue3-easy-data-table__header th {
	border-right: var(--easy-table-row-border)
}

.vue3-easy-data-table__main.border-cell .vue3-easy-data-table__header th:last-of-type {
	border-right: none
}

.vue3-easy-data-table__main.border-cell .vue3-easy-data-table__body td {
	border-right: var(--easy-table-row-border)
}

.vue3-easy-data-table__main.border-cell .vue3-easy-data-table__body td:last-of-type {
	border-right: none
}

.vue3-easy-data-table__body.row-alternation tr.even-row td {
	color: var(--easy-table-body-even-row-font-color);
	background-color: var(--easy-table-body-even-row-background-color)
}

table {
	border-collapse: initial;
	display: table;
	width: 100%;
	border-spacing: 0;
	margin: 0
}

.vue3-easy-data-table__header, .vue3-easy-data-table__body {
	position: relative
}

.vue3-easy-data-table__header tr {
	font-size: var(--easy-table-header-font-size);
	border: none;
	height: var(--easy-table-header-height)
}

.vue3-easy-data-table__header th {
	background-color: var(--easy-table-header-background-color);
	color: var(--easy-table-header-font-color);
	border: none;
	border-bottom: var(--easy-table-row-border);
	padding: var(--easy-table-header-item-padding);
	position: relative
}

.vue3-easy-data-table__header th .header {
	display: flex;
	align-items: center
}

.vue3-easy-data-table__header th .header.direction-left {
	justify-content: flex-start
}

.vue3-easy-data-table__header th .header.direction-center {
	justify-content: center
}

.vue3-easy-data-table__header th .header.direction-right {
	justify-content: flex-end
}

.vue3-easy-data-table__header th.text-end .header.direction-left,
.vue3-easy-data-table__header th.text-end .header.direction-center,
.vue3-easy-data-table__header th.text-end .header.direction-right {
	justify-content: flex-end !important;

	.header-text {
		order: 2;
	}
	.sortType-icon {
		order: 1;
		margin-left: 0;
		margin-right: 4px;
	}
}

.vue3-easy-data-table__header th.sortable {
	cursor: pointer
}

.vue3-easy-data-table__header th.sortable .sortType-icon {
	border: 5px solid transparent;
	margin-top: -3px;
	margin-left: 4px;
	display: inline-block;
	height: 0;
	width: 0;
	position: relative;
	border-bottom-color: var(--easy-table-header-font-color)
}

.vue3-easy-data-table__header th.sortable .multi-sort__number {
	border-radius: 50%;
	height: 1.5em;
	width: 1.5em;
	line-height: 1.5em;
	margin-left: 4px;
	background-color: var(--easy-table-header-font-color);
	color: var(--easy-table-header-background-color)
}

.vue3-easy-data-table__header th.sortable.none:hover .sortType-icon {
	opacity: 1
}

.vue3-easy-data-table__header th.sortable.none .sortType-icon {
	opacity: 0;
	transition: .5s ease
}

.vue3-easy-data-table__header th.sortable.desc .sortType-icon {
	margin-top: 5px;
	transform: rotate(180deg)
}

.vue3-easy-data-table__header th.shadow:after, .vue3-easy-data-table__body td.shadow:after {
	pointer-events: none;
	content: "";
	width: 36px;
	display: inline-block;
	height: 100%;
	position: absolute;
	top: 0px;
	right: -36px;
	box-shadow: none
}

.vue3-easy-data-table__body tr {
	height: var(--easy-table-body-row-height);
	color: var(--easy-table-body-row-font-color);
	font-size: var(--easy-table-body-row-font-size)
}

.vue3-easy-data-table__body tr:last-child td {
	border-bottom: none
}

.vue3-easy-data-table__body tr:first-child td {
	border-bottom: var(--easy-table-row-border)
}

.vue3-easy-data-table__body td {
	padding: var(--easy-table-body-item-padding);
	background-color: var(--easy-table-body-row-background-color);
	border: none;
	border-bottom: var(--easy-table-row-border);
	position: relative
}

.vue3-easy-data-table__body td.direction-left {
	text-align: left
}

.vue3-easy-data-table__body td.direction-center {
	text-align: center
}

.vue3-easy-data-table__body td.direction-right {
	text-align: right
}

.vue3-easy-data-table__body td .expand-icon {
	border: solid;
	border-color: var(easy-table-body-row-font-color);
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(-45deg);
	transition: .2s
}

.vue3-easy-data-table__body td .expand-icon.expanding {
	transform: rotate(45deg)
}

.vue3-easy-data-table__body td.expand {
	position: relative
}

.vue3-easy-data-table__body td.expand .expand-loading {
	position: absolute;
	top: 0px;
	left: 0px
}

.vue3-easy-data-table__body td.can-expand {
	cursor: pointer
}

.vue3-easy-data-table__footer {
	background-color: var(--easy-table-footer-background-color);
	color: var(--easy-table-footer-font-color);
	border-top: var(--easy-table-row-border);
	font-size: var(--easy-table-footer-font-size);
	height: var(--easy-table-footer-height);
	padding: var(--easy-table-footer-padding);
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end
}

.vue3-easy-data-table__footer .pagination__rows-per-page {
	display: flex;
	flex-direction: row-reverse;
	align-items: center
}

.vue3-easy-data-table__footer .pagination__items-index {
	margin: 0 20px 0 10px
}

.vue3-easy-data-table__message {
	color: var(--easy-table-message-font-color);
	font-size: var(--easy-table-message-font-size);
	padding: var(--easy-table-message-padding);
	text-align: center
}

.vue3-easy-data-table__loading {
	z-index: 3;
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center
}

.vue3-easy-data-table__loading .loading-entity {
	z-index: 1
}

.vue3-easy-data-table__loading-mask {
	background-color: var(--easy-table-loading-mask-background-color);
	opacity: var(--easy-table-loading-mask-opacity);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1
}

.vue3-easy-data-table__main {
	min-height: var(--4546a7ac)
}

.vue3-easy-data-table__main.fixed-height {
	height: var(--705db878)
}
