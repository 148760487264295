.page-content {
	min-height: 100vh;
}
.loading {
	position: relative;
	min-height: 200px;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 10005;
		background: rgba(0, 0, 0, .5);
		cursor: wait;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 10006;
		width: 48px;
		height: 48px;
		border: 5px solid #FFF;
		border-bottom-color: $primary;
		border-radius: 50%;
		box-sizing: border-box;
		animation: loader-rotation 1s linear infinite;
		margin: -24px 0 0 -24px;
		cursor: wait;
	}

	&.loading--light {
		&:before {
			background: rgba(255, 255, 255, .5);
			box-shadow: 0 0 15px #fff;
		}
	}
}
html.loading {
	&:after, &:before {
		position: fixed;
	}
}
.card {
	.loading {
		&:before {
			background: rgba(255, 255, 255, .5);
		}
	}
}
.card.loading {
	overflow: hidden;

	&:before {
		background: rgba(255, 255, 255, .5);
	}
}
.btn-loading {
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 10005;
		background: rgba(255, 255, 255, .5);
		cursor: wait;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 10006;
		width: 24px;
		height: 24px;
		border: 5px solid #FFF;
		border-bottom-color: $primary;
		border-radius: 50%;
		box-sizing: border-box;
		animation: loader-rotation 1s linear infinite;
		margin: -12px 0 0 -12px;
		cursor: wait;
	}
}
.form-control {
	line-height: 20px;
	padding: 9px 12px;
	font-size: 14px;
}
.form-control-color {
	height: 41px !important;
}
@keyframes loader-rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.btn {
	line-height: 21px;
	padding: 9px 12px;
}
.btn-label {
	padding-left: 44px;

	.label-icon {
		height: 41px;
	}
}
.btn-sm{
	padding: 4px 8px;
	line-height: 17px;

	&.btn-label {
		padding-left: 33px;

		.label-icon {
			height: 24px;
		}
	}
}
.btn-bg-light {
	color: $body-color !important;
}
table .btn {
	white-space: nowrap;
}
.card-header {
	padding-top: 14px;
	padding-bottom: 14px;
}
.vue-dropzone,
.dropzone {
	background: var(--bs-card-cap-bg) !important;
	border-color: var(--bs-body-bg) !important;

	&:hover {
		background: var(--bs-body-bg) !important;
		color: var(--bs-heading-color) !important;
		border-color: var(--bs-body-bg) !important;
	}
}
.dz-download {
	margin-top: 10px;
	padding-left: 0.4em;

	.btn {
		cursor: pointer !important;
	}
}
.dropzone-compact {
	.dropzone {
		min-height: 0;
		.dz-message {
			margin: 0;
		}
	}
}
.alert {
	font-size: 15px;
}
.alert-sm {
	font-size: 14px;
}
.alert-success {
	background: var(--bs-success);
	color: var(--bs-white);
	border-color: var(--bs-success);
}
.alert-danger {
	background: var(--bs-danger);
	color: var(--bs-white);
	border-color: var(--bs-danger);
}
.alert-warning {
	background: var(--bs-warning);
	color: var(--bs-white);
	border-color: var(--bs-warning);
}
.alert-info {
	background: var(--bs-info);
	color: var(--bs-white);
	border-color: var(--bs-info);
}
.alert-primary {
	background: var(--bs-primary);
	color: var(--bs-white);
	border-color: var(--bs-primary);
}
.alert-secondary {
	background: var(--bs-secondary);
	color: var(--bs-white);
	border-color: var(--bs-secondary);
}
.form-control {
	//background: var(--bs-card-cap-bg);
}
.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
	padding-left: 0;
}
.btn-label.btn-sm {
	padding-left: 28px;

	.label-icon {
		font-size: 12px;
		width: 24px;
	}
}
.vue-dropzone > .dz-preview .dz-remove.btn {
	border: 0 none !important;
	text-transform: none !important;
	font-weight: normal !important;
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) var(--bs-btn-padding-y) 44px;
	letter-spacing: 0;
}
.v-select {
	font-size: 13px;
}
.vs__selected-options {
	min-height: 35px;
}
.vs__search, .vs__search:focus {
	font-size: 13px;
}
.form-switch {
	padding-left: 3.5em;
}
.form-switch .form-check-input {
	width: 3em;
	height: 1.5em;
	margin-left: -3.5em;
	margin-top: -2px;
}
label.required:after, h4.required:after {
	content: " *";
	color: $danger;
}
.card-header {
	background: transparent;
}
.card-footer {
	background: #fff;
}
.project-type-badge,
.colored-badge {
	display: inline-flex;
	padding: 0.4em 0.8em;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	white-space: nowrap;
	border-radius: 0.4em;
	align-items: center;


	&.project-type-badge-light,
	&.colored-badge-light {
		color: $body-color;
	}
	&.project-type-badge-dark,
	&.colored-badge-dark {
		color: #fff;
	}
	i {
		margin-right: 6px;

		&:last-child {
			margin-right: 0;
		}
	}
}
.colored-badge-sm {
	padding: 0.2em 0.4em;
	font-size: 11px;
	border-radius: 0.3em;
}
.colored-badge-xl {
	padding: 0.5em 0.9em;
	font-size: 14px;
	border-radius: 0.4em;
}
.vs__selected {
	.project-type-badge {
		white-space: normal;
	}
}
.badge-xl {
	padding: 0.4em 0.8em;
	font-size: 12px;
	font-weight: 500;
}
.milestone-badge {
	display: inline-flex;
	padding: 0.1em 0.5em;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	white-space: nowrap;
	border-radius: 0.4em;
	align-items: center;

	i {
		margin-right: 6px;
	}

	&.milestone-badge-light {
		color: $body-color;
	}
	&.milestone-badge-dark {
		color: #fff;
	}

	&.milestone-badge-inactive {
		opacity: .3;
	}
}
.vs--single.vs--loading .vs__selected, .vs--single.vs--open .vs__selected {
	top: 5px;
}
.growing-textarea textarea {
	display: block;
	width: 100%;
	font-family: $input-font-family;
	font-weight: $input-font-weight;
	color: $input-color;
	background-color: $input-bg;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;
	appearance: none;
	line-height: 20px;
	padding: 9px 12px;
	font-size: 14px;

	@include border-radius($input-border-radius, 0);

	@include box-shadow($input-box-shadow);
	@include transition($input-transition);


	&:focus {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		border-color: $input-focus-border-color;
		outline: 0;
		@if $enable-shadows {
			@include box-shadow($input-box-shadow, $input-focus-box-shadow);
		} @else {
			// Avoid using mixin so we can pass custom focus shadow properly
			box-shadow: $input-focus-box-shadow;
		}
	}
}
.vs__dropdown-toggle {
	background: #fff !important;
}
.vs--disabled {
	.vs__dropdown-toggle, .vs__search {
		background: var(--bs-gray-200) !important;
	}
}
.select-invalid,
.date-invalid,
.radio-button-invalid{
	.vs__dropdown-toggle {
		border-color: $danger !important;
	}
	.invalid-feedback {
		display: block;
	}
	.form-control {
		border-color: $danger !important;
	}
}
.vs__search, .vs__search:focus {
	font-size: 14px !important;
}
.file-upload-wrapper {
	border: 2px dashed var(--bs-border-color);
	padding: 1em;
	text-align: center;
	background: var(--bs-custom-white);
	font-size: 1.4em;
	border-radius: 6px;
	margin-bottom: 15px;
}
.file-upload-wrapper:hover {
	border: 2px dashed var(--bs-black);
}
.td-image-thumb {
	max-width: 4em;
	max-height: 4em;
	cursor: pointer;
}
.progress {
	height: 18px;
	.progress-bar {
		font-weight: bold;
	}
}
.filename {
	@include media-breakpoint-up(md) {
		max-width: 20vw;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
.lightbox {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0,0,0,.7);
	cursor: pointer;
	z-index: 50000;
	padding: 5vh 5vw;

	.btn {
		position: absolute;
		right: 15px;
		top: 15px;
	}
}
.lightbox-image {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}
.file-icon {
	text-align: center;
	i {
		font-size: 40px;
	}
}
.card.bg-light {
	background: rgba(85, 110, 230, 0.1);
}
.details-box {
	padding: 7px 10px;
	background: var(--bs-light);
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;
	font-size: 13px;

	> div {
		flex-basis: 50%;
		max-width: 50%;
		padding: 5px 0;
		position: relative;
		align-items: center;
		line-height: 16px;
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@include media-breakpoint-down(sm){
			flex-basis: 100%;
			max-width: 100%;
		}

		&.full-width {
			flex-basis: 100% !important;
			max-width: 100% !important;
		}

		&.text {
			align-items: flex-start;

			p {
				margin-bottom: 5px;
			}
		}

		> i:first-child {
			width: 20px;
			text-align: center;
			margin-right: 6px;
			flex-shrink: 0;
		}

		> strong, > a, > span, > div {
			margin-left: 6px;
			text-overflow: ellipsis;
		}

		a {
			font-weight: bold;
		}

		&.break {
			flex-basis: 100% !important;
			max-width: 100% !important;
			height: 1px;
			padding: 0;
			margin: 4px 0;
			background: #fff;
		}
	}
	> div.break:last-child,
	> div.break + div.break {
		margin: 0;
		background: transparent;
		height: 0;
	}

	h4 {
		flex-basis: 100%;
		font-size: 15px;
		font-weight: 600;
		padding: 5px 0 0 5px;
		margin-bottom: 10px;
	}

	&.details-box--md {
		> div {
			@include media-breakpoint-up(xxl){
				flex-basis: 50%;
				max-width: 50%;
			}
		}
	}

	&.details-box--narrow {
		> div {
			flex-basis: 100%;
			max-width: 100%;
		}
	}
}

.vs__dropdown-toggle {
	border-color: var(--bs-input-border-color) !important;
}
.vs__dropdown-menu {
	border-color: var(--bs-input-focus-border-color) !important;
}
.vs--open {
	.vs__dropdown-toggle {
		border-color: var(--bs-input-focus-border-color) !important;
	}
}
.data-table-bootstrap thead th {
	border-bottom: 1px solid rgb(215, 218, 222) !important;
	font-weight: 600 !important;
}
.data-table-compact {
	.vue3-easy-data-table__main {
		min-height: auto !important;
	}
}
.data-table-bootstrap {
	--easy-table-header-font-color: #000;
	--easy-table-header-item-padding: 0.75rem 0.75rem;
	--easy-table-header-font-size: 13px;

	--easy-table-border: none !important;
	--easy-table-row-border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
	--easy-table-body-row-font-color: var(--bs-table-color);

	--easy-table-header-background-color: #eff2f7;
	--easy-table-body-item-padding: 0.75rem 0.75rem;


	--easy-table-body-even-row-font-color: var(--bs-table-bg);
	--easy-table-body-even-row-background-color: var(--bs-table-striped-bg);


	--easy-table-body-row-hover-font-color: var(--bs-table-hover-color);
	--easy-table-body-row-hover-background-color: #F8F9FA;

	tr.table-info {
		td {
			background: #DCEDFC !important;
		}
	}
}
.vue3-easy-data-table__footer {
	color: var(--bs-card-color) !important;
	padding-top: 14px !important;
}
.modal-top {
	z-index: 51001;
}
.modal-backdrop-top {
	z-index: 51000;
}

.clear-search {
	cursor: pointer;
}
.clear-search:hover {
	color: var(--bs-danger);
}
.overlay-form-wrapper {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 50005;
	background: rgba(0, 0, 0, .5);
	padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include media-breakpoint-down(sm){
		padding: 0;
	}
}
.overlay-form-wrapper--content {
	background: var(--bs-body-bg);
	padding: 30px;
	min-height: 150px;
	max-height: calc(100vh - 60px);
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;

	@include media-breakpoint-down(sm){
		max-height: 100vh;
		padding: 30px 15px;
	}

	&.overlay-form-wrapper--content-small {
		max-width: 800px;
	}

	&.loading {
		&:before {
			background: #FFF;
		}
	}
}
.mb-last-0:last-child {
	margin-bottom: 0 !important;
}
td {
	input[switch] + label {
		margin: 0;
	}
}
.input-group {
	.btn-secondary {
		background: var(--bs-gray-200);
		color: var(--bs-gray-700);
		border: 1px solid var(--bs-input-border-color);

		&:hover {
			background: var(--bs-gray-200);
			color: var(--bs-gray-700);
			border: 1px solid var(--bs-input-focus-border-color);
		}
	}
}
td .highlighted {
	margin: -12px;
	height: 100%;


	.highlighted--icon {
		font-weight: bold;
		font-size: 20px;
		background: $dark;
		color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;

		i {
			margin: 0 !important;
		}
	}
}

.inactivity-overlay-bg {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10000;
	background: rgba(0,0,0,.4);
}
.inactivity-overlay {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 30px;
	text-align: center;
	z-index: 10001;
	border: 5px solid var(--bs-danger-rgb);
}
.inactivity-overlay p {
	font-size: 20px;
}
.vertical-align-middle {
	vertical-align: middle;
}
.document-types-list-group {
	border-radius: 0 !important;

	.list-group-item {
		padding-left: 25px;
		position: relative;
	}
	.list-group-item-action-active {
		background-color: #74788d;
		color: #fff;
	}
	.list-group-item-border {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 10px;

	}
}
p:last-child {
	margin-bottom: 0;
}
.switch-wrapper {
	height: 41px;
	display: flex;
	align-items: center;

	label {
		margin: 0;
	}
}
.card-header:first-child {
	border-radius: 0.25rem 0.25rem 0 0;
	overflow: hidden;
}
.file-list {
	grid-gap: 10px;
	> div {
		width: calc(50% - 5px);
		@include media-breakpoint-down(sm){
			width: 100%;
		}
	}
}
.project-card .row-icon {
	font-size: 22px;
	margin-right: 12px;
	width: 40px;
	text-align: center;

	&.big {
		font-size: 30px;
	}
}
.project-card-attributes {
	padding: 0 10px;
}
.project-card-attribute {
	padding: 20px 10px;
	border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
	display: flex;
	align-items: flex-start;

	&:last-child {
		border-bottom: 0 none;
	}
}
.table-collapse-mobile {
	@include media-breakpoint-down(md){
		thead {
			display: none;
		}
		tbody, tfoot {
			display: block;
			tr {
				border: 1px solid var(--bs-border-color);
				margin-bottom: 10px;
				display: block;

				td, th {
					display: flex;
					border: none;
					width: 100%;
					padding: 0.4rem 0.75rem;

					&.column-on-mobile {
						flex-direction: column;
					}

					&:nth-child(2n+1){
						background: var(--bs-table-striped-bg);
					}

					&[data-label]{
						&:before {
							content: attr(data-label) ": ";
							font-weight: bold;
							display: block;
							margin-right: 10px;
						}
					}

					&.optional {
						margin-top: -8px;
					}
				}
			}
		}

		.v-select {
			width: 100%;
		}
		.td-image-thumb {
			max-width: 8em;
			max-height: 8em;
		}
	}
}
.attribute-table-collapse-mobile {
	@include media-breakpoint-down(md) {
		thead {
			display: none;
		}
		tbody, tfoot {
			display: block;

			tr {
				border-bottom: 1px solid var(--bs-border-color);
				display: block;

				&:last-child {
					border-bottom: none;
				}

				td, th {
					border: none;
					display: block;
				}
				td {
					padding-top: 0.1rem;
				}
				th {
					padding-bottom: 0.1rem;
				}
			}
		}
	}
}
.project-attribute--label {
	max-width: 15vw;
	flex-basis: 15vw;
	flex-shrink: 0;
	padding-right: 20px;

	@include media-breakpoint-down(md){
		max-width: 100%;
		flex-basis: 100%;
		padding-right: 0;
	}
}
.project-attribute--wrapper {
	display: flex;
	@include  media-breakpoint-down(md){
		flex-wrap: wrap;
	}
}
.project-attribute--label-editable {
	padding-top: 10px;
}
.project-attribute--label label {
	margin: 0;
}
.table-row-operations {
	margin: -4px;
}

.sortable-form-card--left {
	flex-basis: 100px;
	flex-shrink: 0;
	padding: 10px;
	border-right: 1px solid #eff2f7;
	display: flex;
	flex-direction: column;
}
.sortable-form-card--left button {
	margin-bottom: 10px;
}
.sortable-form-card--right {
	flex-basis: calc(100% - 100px);
	padding: 10px 10px 10px 20px;
}
.vue3-easy-data-table__main {
	overflow: visible !important;
}
.nav-pills.nav-justified {
	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
	}
	.nav-link {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 12px;
		padding-left: 8px;
		padding-right: 8px;

		.icon {
			margin-bottom: 5px;
			position: relative;
			.badge {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(0, -20px);
			}
		}
		.label {
			font-size: 12px;

			@include media-breakpoint-down(sm){
				display: none;
			}
		}

		i {
			font-size: 22px;
		}
	}
	.nav-item {

		.far,
		.fas,
		.fal,
		.fab {
			text-align: center;
			width: 1.25em;
		}
	}
}
.comment-info {
	@include media-breakpoint-down(md){
		flex-direction: column;
	}

}
@include media-breakpoint-down(md){
	.page-content {
		padding: calc(70px + 24px) 5px 60px 5px
	}
	.card {
		margin-left: -5px;
		margin-right: -5px;

		.card-body {
			padding: 10px;
		}
	}
	.vue3-easy-data-table {

		table {
			display: block;

			thead {
				display: none;
			}

			tbody, tr, td {
				display: block !important;
				height: auto !important;
			}

			tbody {
				tr {
					padding: 0 !important;
					border: 1px solid $table-border-color !important;
					position: relative;
					margin-bottom: 10px;

					&:hover {
						background-color: transparent !important;

						td {
							background-color: $white !important;
						}
					}

					td {
						padding: 10px 58px 10px 10px !important;
						border-bottom: 0 none !important;
						position: static;
						min-height: 38px;

						&:empty {
							display: none;
						}

						&:nth-child(2n+1){
							background: #F8F9FA !important;
						}

						&:last-child {
							position: absolute;
							padding: 10px !important;
							right: 0;
							top: 0;
							bottom: 0;
							background: #CCCDCF !important;
						}

						&.text-end {
							text-align: left !important;
						}
					}

					.v-select {
						width: 100%;
					}

					.table-row-operations {
						flex-direction: column;
						margin: 0;

						.btn {
							flex-grow: 1;
						}

						div:not(.dropdown-menu) {
							display: flex;
						}
						.dropdown-menu{
							right: 0;
							left: auto;
						}
					}
				}
			}
		}
	}
	.data-table-filter-wrapper {
		.input-group {
			margin-bottom: 10px;
		}
	}
	.vue3-easy-data-table__footer {
		flex-wrap: wrap;
		justify-content: center;
		height: auto !important;
	}
	.pagination__rows-per-page {
		width: 100%;
		margin-bottom: 10px;
		justify-content: center;
		flex-direction: row-reverse;
	}
	.pagination__items-index {
		width: 100%;
		text-align: center;
		margin-bottom: 10px !important;
	}

}

.navbar-brand-box {
	height: 70px;
	.logo {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
	}
	.small-logo {
		display: none;
	}
}
body.vertical-collpsed {
	.navbar-brand-box {
		.normal-logo {
			display: none;
		}
		.small-logo {
			display: block;
		}
	}
}
.main-content {
	.container-fluid {
		position: relative;
		z-index: 99;
	}
}
body.client-area {
	.topnav {
		position: relative;
		margin-top: 0;
		z-index: auto;
	}
	#page-topbar {
		position: relative;
		z-index: auto;
	}
	.page-content {
		margin-top: 0;
		padding: 30px 12px 70px;
	}
}
.single-checkbox {
	font-size: 20px;
	cursor: pointer;
}
td.button-cell {
	padding: 4px !important;
	vertical-align: middle;
}
.mx-datepicker-popup {
	z-index: 50010 !important;
}
.footer {
	z-index: 50;
}
.radio-field {
	scroll-margin-top: 20px;
}
.vs__dropdown-option--selected {
	background: $light;
}
.vs__dropdown-option {
	white-space: normal !important;
}
.vs__dropdown-option--highlight {
	color: #fff;
	background: $primary;
}
.btn-secondary-light {
	background: #89898f;
}