$primaryColor:'0,206,238';
/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

@import "app_functions";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datatable";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/projects";
@import "custom/pages/crypto";

@import "dataTable";
@import "app_style";
@import "vue-select/dist/vue-select.css";
@import "vue-datepicker-next/index.css";